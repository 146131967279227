<template>
  <div>
    <div class="banner">
      <b-carousel id="carousel-1" :interval="4000" indicators>
        <b-carousel-slide v-for="(entry, index) in customerBanners" :key="index" :id="'client' + index">
          <template v-slot:img>
            <a :href="entry.link" target="_blank" @click="
              sendClickToGA(entry.category, entry.action, entry.label)
            ">
              <img :src="require('@/assets/images/banner/' + entry.fileName)" />
            </a>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <br />

    <div class="notification desktop" v-if="dashboardInfos && parseInt(filterDashboardInfos('PIN_REQUEST')) > 0">
      <table cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr>
          <td width="7%" valign="top">
            <img src="@/assets/images/alerte.svg" />
          </td>
          <td width="65%" valign="top">
            <h2>
              {{
                $t("dashboardInfoReissuePINTitle", {
                  count: parseInt(filterDashboardInfos("PIN_REQUEST"))
                })
              }}
            </h2>
            <p class="gri">{{ $t("dashboardInfoReissuePINMessage") }}</p>
          </td>
          <td width="28%">
            <a :href="
              '/customer/beneficiaries?status_card=RP&product=' +
              this.selectedProduct.card
            " class="notification-action">{{ $t("dashboardInfoReissuePINButton") }}</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="notification desktop" v-if="
      dashboardInfos && parseInt(filterDashboardInfos('CARD_REQUEST')) > 0
    ">
      <table cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr>
          <td width="7%" valign="top">
            <img src="@/assets/images/alerte.svg" />
          </td>
          <td width="65%" valign="top">
            <h2>
              {{
                $t("dashboardInfoReissueCardTitle", {
                  count: parseInt(filterDashboardInfos("CARD_REQUEST"))
                })
              }}
            </h2>
            <p class="gri">{{ $t("dashboardInfoReissuePINMessage") }}</p>
          </td>
          <td width="28%">
            <a :href="
              '/customer/beneficiaries?status_card=RC,RV&product=' +
              this.selectedProduct.card
            " class="notification-action">{{ $t("dashboardInfoReissueCardButton") }}</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="notification desktop" v-if="
      dashboardInfos && parseInt(filterDashboardInfos('CARD_ISSUE')) > 0
    ">
      <table cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr>
          <td width="7%" valign="top">
            <img src="@/assets/images/alerte.svg" />
          </td>
          <td width="65%" valign="top">
            <h2>
              {{
                $t("dashboardInfoIssueCardTitle", {
                  count: parseInt(filterDashboardInfos("CARD_ISSUE"))
                })
              }}
            </h2>
            <p class="gri">{{ $t("dashboardInfoReissuePINMessage") }}</p>
          </td>
          <td width="28%">
            <a :href="
              '/customer/beneficiaries?status_card=RM&product=' +
              this.selectedProduct.card
            " class="notification-action">{{ $t("dashboardInfoIssueCardButton") }}</a>
          </td>
        </tr>
      </table>
    </div>

    <div class="tabs-container">
      <div class="tabs">
        <a v-for="(entry, index) in customer.products" :key="entry.name" @click="selectProduct(index)"
          :class="entry.name === selectedProduct.name && 'active'">
          <img :src="
            require('@/assets/images/logo-up-' +
              (entry.card_profile && entry.product === 15
                ? entry.card_profile
                : entry.card
                  ? entry.card
                  : entry.paper) +
              '.png')
          " />
        </a>
      </div>
    </div>

    <div class="allstats-customer">
      <div class="content-allstats">
        <div class="balance-circle mobile" :class="
          'border-color-' +
          (selectedProduct.card
            ? selectedProduct.card
            : selectedProduct.paper)
        ">
          <img :src="
            require('@/assets/images/logo-up-' +
              (selectedProduct.card
                ? selectedProduct.card
                : selectedProduct.paper) +
              '.png')
          " />
        </div>
        <div class="arrow-left" @click="previousProduct()">
          <img src="@/assets/images/arrow-left.svg" />
        </div>

        <div class="arrow-right" @click="nextProduct()">
          <img src="@/assets/images/arrow-right.svg" />
        </div>

        <p>
          <strong>{{ $t("pendingOrdersNoOrders") }}</strong>
        </p>
        <!-- <div
          v-for="(entry, index) in lastOrders"
          :key="index"
        >
          <p>
            <strong>{{ $t("dashboardTrackingOrderTitle", {
                product: selectedProduct.name,
                date: moment(entry.date).format("DD MMM YYYY"),
                amount: entry.total_amount_value + " lei"
              }) }}</strong>
          </p>

          <div class="order-trackbar">
            <div
              class="order-statusbar"
              :class="entry.awb"
            >
              <div class="arrow">
                <img src="@/assets/images/track-arrow.svg" />
              </div>
            </div>
          </div>
          <div
            v-for="(entry, index) in statusOrder"
            :key="index"
            class="status-text border-box"
          >
            <small>
              {{ entry.status }}
              <br />
              {{ entry.date }}
            </small>
          </div>
        </div>-->

        <br clear="all" />
      </div>
      <div class="navigator mobile">
        <div v-for="(entry, index) in customer.products" :key="index" @click="selectProduct(index)" :class="
          entry.name === selectedProduct.name ? 'bubble' : 'bubble-stroke'
        "></div>
      </div>
    </div>

    <div class="mobile-banner">
      <b-carousel id="carousel-2" :interval="4000">
        <b-carousel-slide v-for="(entry, index) in customerBanners" :key="index" :id="'client' + index">
          <template v-slot:img>
            <a :href="entry.link" target="_blank" @click="
               sendClickToGA(entry.category, entry.action, entry.label)
            ">
              <img :src="require('@/assets/images/banner/' + entry.fileName)" />
            </a>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>

    <div class="notification mobile" v-if="dashboardInfos && parseInt(filterDashboardInfos('PIN_REQUEST')) > 0">
      <table cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr>
          <td width="7%" valign="top">
            <img src="@/assets/images/alerte.svg" />
          </td>
          <td width="65%" valign="top">
            <h2>
              {{
                $t("dashboardInfoReissuePINTitle", {
                  count: parseInt(filterDashboardInfos("PIN_REQUEST"))
                })
              }}
            </h2>
            <p class="gri">{{ $t("dashboardInfoReissuePINMessage") }}</p>
          </td>
          <td width="28%">
            <a :href="
              '/customer/beneficiaries?status_card=RP&product=' +
              this.selectedProduct.card
            " class="notification-action">{{ $t("dashboardInfoReissuePINButton") }}</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="notification mobile" v-if="
      dashboardInfos && parseInt(filterDashboardInfos('CARD_REQUEST')) > 0
    ">
      <table cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr>
          <td width="7%" valign="top">
            <img src="@/assets/images/alerte.svg" />
          </td>
          <td width="65%" valign="top">
            <h2>
              {{
                $t("dashboardInfoReissueCardTitle", {
                  count: parseInt(filterDashboardInfos("CARD_REQUEST"))
                })
              }}
            </h2>
            <p class="gri">{{ $t("dashboardInfoReissuePINMessage") }}</p>
          </td>
          <td width="28%">
            <a :href="
              '/customer/beneficiaries?status_card=RC,RV&product=' +
              this.selectedProduct.card
            " class="notification-action">{{ $t("dashboardInfoReissueCardButton") }}</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="notification mobile" v-if="
      dashboardInfos && parseInt(filterDashboardInfos('CARD_ISSUE')) > 0
    ">
      <table cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr>
          <td width="7%" valign="top">
            <img src="@/assets/images/alerte.svg" />
          </td>
          <td width="65%" valign="top">
            <h2>
              {{
                $t("dashboardInfoIssueCardTitle", {
                  count: parseInt(filterDashboardInfos("CARD_ISSUE"))
                })
              }}
            </h2>
            <p class="gri">{{ $t("dashboardInfoReissuePINMessage") }}</p>
          </td>
          <td width="28%">
            <a :href="
              '/customer/beneficiaries?status_card=RM&product=' +
              this.selectedProduct.card
            " class="notification-action">{{ $t("dashboardInfoIssueCardButton") }}</a>
          </td>
        </tr>
      </table>
    </div>

    <div class="banner-stats" v-if="dashboardInfos">
      <div class="circle2 stats-circle">
        <h1 v-html="
          $t('dashboardInfoTitle_TOTAL_ORDERS_AMOUNT', {
            value: parseInt(filterDashboardInfos('TOTAL_ORDERS_AMOUNT'))
          })
        " />
        <small v-html="$t('dashboardInfo_TOTAL_ORDERS_AMOUNT')"></small>
      </div>
      <div class="circle1 stats-circle">
        <h1 v-html="
          $t('dashboardInfoTitle_TOTAL_BENEFICIARIES', {
            value: this.cardStatisticsData.total_card
              ? parseInt(this.cardStatisticsData.total_card)
              : 0
          })
        " />
        <small v-html="$t('dashboardInfo_TOTAL_BENEFICIARIES')"></small>
      </div>
      <div class="banner-square">
        <b-carousel id="carousel-1" :interval="4000" indicators>
          <b-carousel-slide>
            <template v-slot:img>
              <img src="@/assets/images/cross sell Up Dejun.jpeg" style="width: 100%" />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img src="@/assets/images/cross sell Up Cadou.jpeg" style="width: 100%" />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img src="@/assets/images/cross sell Up Vacanta.jpeg" style="width: 100%" />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img src="@/assets/images/cross sell Up Cultura.jpeg" style="width: 100%" />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <br clear="all" />
    </div>

    <div class="container-alb">
      <div class="error-wrapper border-box" v-if="this.customer.electronic_invoice === false">
        <NoElectronicInvoiceTemplate />
      </div>
      <div v-else>
        <p class="invoice-title">
          <strong>{{ $t("lastInvoicesTitle") }}</strong>
        </p>
        <br />
        <div v-if="
          this.customerLastInvoices && this.customerLastInvoices.length > 0
        ">
          <InvoiceTableDesktop :company="this.customer" :companyInvoices="this.customerLastInvoices" />
          <div class="text-center mt-5">
            <a :href="'/customer/invoices?product=' + this.selectedProduct.card" class="notification-action">{{
              $t("seeAllInvoicesButtonLabel") }}</a>
          </div>
        </div>
        <div class="error-wrapper border-box" v-else>
          {{ $t("noInvoicesMessage") }}
        </div>
      </div>
    </div>

    <div v-if="this.selectedProduct.card > 0">
      <div class="container-alb">
        <div class="usage-volume-container">
          <div class="statuses">
            <p>
              <strong>{{ $t("customerDashboardCardStats") }}</strong>
            </p>
            <br />

            <table cellpadding="5" cellspacing="0" width="100%;">
              <tr>
                <td width="30%" align="right">{{ $t("statsCardsActive") }}</td>
                <td align="left">
                  <div class="grafica" @click="selectCardOffer(0)">
                    <div class="grafica-fill" :class="this.currentCardOffer.id === 1 && 'active-dark'" :style="
                      getStyle(
                        (
                          (this.cardStatisticsData.activated /
                            this.cardStatisticsData.total_card) *
                          100
                        ).toFixed(2)
                      )
                    "></div>
                    <span v-if="this.cardStatisticsData.total_card">
                      {{ this.cardStatisticsData.activated }} /
                      {{ this.cardStatisticsData.total_card }}
                    </span>
                    <span v-else>
                      0 / 0
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="30%" align="right">{{ $t("statsCardsBlocked") }}</td>
                <td align="left">
                  <div class="grafica" @click="selectCardOffer(1)">
                    <div class="grafica-fill" :class="this.currentCardOffer.id === 2 && 'active-dark'" :style="
                      getStyle(
                        (
                          (this.cardStatisticsData.blocked /
                            this.cardStatisticsData.total_card) *
                          100
                        ).toFixed(2)
                      )
                    "></div>
                    <span v-if="this.cardStatisticsData.total_card">
                      {{ this.cardStatisticsData.blocked }} /
                      {{ this.cardStatisticsData.total_card }}
                    </span>
                    <span v-else>
                      0 / 0
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="30%" align="right">
                  {{ $t("statsCardsInactive") }}
                </td>
                <td width="60%" align="left">
                  <div class="grafica" @click="selectCardOffer(2)">
                    <div class="grafica-fill" :class="this.currentCardOffer.id === 3 && 'active-dark'" :style="
                      getStyle(
                        (
                          (this.cardStatisticsData.created /
                            this.cardStatisticsData.total_card) *
                          100
                        ).toFixed(2)
                      )
                    "></div>
                    <span v-if="this.cardStatisticsData.total_card">
                      {{ this.cardStatisticsData.created }} /
                      {{ this.cardStatisticsData.total_card }}
                    </span>
                    <span v-else>
                      0 / 0
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="30%" align="right">{{ $t("statsCardsSMS") }}</td>
                <td width="60%" align="left">
                  <div class="grafica" @click="selectCardOffer(3)">
                    <div class="grafica-fill" :class="this.currentCardOffer.id === 4 && 'active-dark'" :style="
                      getStyle(
                        (
                          (this.cardStatisticsData.with_sms /
                            this.cardStatisticsData.total_card) *
                          100
                        ).toFixed(2)
                      )
                    "></div>
                    <span v-if="this.cardStatisticsData.total_card">
                      {{ this.cardStatisticsData.with_sms }} /
                      {{ this.cardStatisticsData.total_card }}
                    </span>
                    <span v-else>
                      0 / 0
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="30%" align="right">
                  {{ $t("statsCardsDisabled") }}
                </td>
                <td width="60%" align="left">
                  <div class="grafica" @click="selectCardOffer(4)">
                    <div class="grafica-fill" :class="this.currentCardOffer.id === 5 && 'active-dark'" :style="
                      getStyle(
                        (
                          (this.cardStatisticsData.less_funds /
                            this.cardStatisticsData.total_card) *
                          100
                        ).toFixed(2)
                      )
                    "></div>
                    <span v-if="this.cardStatisticsData.total_card">
                      {{ this.cardStatisticsData.less_funds }} /
                      {{ this.cardStatisticsData.total_card }}
                    </span>
                    <span v-else>
                      0 / 0
                    </span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="recommandations">
            <p class="orange">
              <strong>{{ $t("recommendationsTitle") }}</strong>
            </p>
            <br />
            <div class="card-recomandations">
              <div class="left-arrow" @click="previousCardOffer()">
                <img src="@/assets/images/arrow-left.svg" />
              </div>
              <div class="content-rec">
                <h2>{{ this.currentCardOffer.text }}</h2>
              </div>
              <div class="right-arrow" @click="nextCardOffer()">
                <img src="@/assets/images/arrow-right.svg" />
              </div>
            </div>
            <br clear="all" />
          </div>

          <br clear="all" />
        </div>
      </div>

      <div class="filter">
        <select @change="selectPeriod" v-model="selectedPeriod">
          <option value="1">{{ $t("transactionStatsDDLLastMonth") }}</option>
          <option value="3">
            {{ $t("transactionStatsDDLLastMonths", { index: 3 }) }}
          </option>
          <option value="6">
            {{ $t("transactionStatsDDLLastMonths", { index: 6 }) }}
          </option>
          <option value="9">
            {{ $t("transactionStatsDDLLastMonths", { index: 9 }) }}
          </option>
          <option value="12">
            {{ $t("transactionStatsDDLLastMonths", { index: 12 }) }}
          </option>
        </select>
      </div>
      <div v-if="this.totalTransactionMap > 0">
        <div class="numbers-left">
          <div class="container-alb">
            <h1>
              {{ this.cardStatisticsData.created }}
              <sup>/{{ this.cardStatisticsData.total_card }}</sup>
            </h1>
            <p>{{ $t("statsCardsInactive") }}</p>
          </div>
          <div class="container-alb">
            <h1>
              {{ Math.floor(this.averageTransactionAmount) }}
              <sup>
                ,{{
                  (
                    this.averageTransactionAmount -
                    Math.floor(this.averageTransactionAmount)
                  ).toFixed(2) * 100
                }}{{ $t("currency") }}
              </sup>
            </h1>
            <p>{{ $t("averageTransactionAmount") }}</p>
          </div>
          <div class="container-alb">
            <h1>{{ this.averageTransactionNumber }}</h1>
            <p>{{ $t("averageTransactionNumber") }}</p>
          </div>
        </div>
        <div class="usage-volume">
          <div class="container-alb">
            <div class="usage-volume-container">
              <p>{{ $t("usageVolumeChartTitle") }}</p>
              <br />
              <br />
              <TransactionChartCompany v-if="this.transactionChartData" :chartData="this.transactionChartData"
                :height="320" />
            </div>
          </div>
        </div>
        <br clear="all" />
        <br />

        <div v-if="
          this.selectedProduct.brand !== 'vacanta' ||
          (this.cardStatisticsData.total_card >= 15 &&
            this.selectedProduct.brand === 'vacanta')
        " class="usage-country">
          <div class="container-alb">
            <div class="usage-volume-container">
              <table cellpadding="0" cellspacing="0" width="100%">
                <tr>
                  <td align="left">
                    <p>
                      <strong>{{ $t("usagePerRegion") }}</strong>
                    </p>
                  </td>
                  <td align="right"></td>
                </tr>
              </table>

              <br />
              <br />
              <MapTemplate v-if="this.transactionMapData" :countyList="this.transactionMapData"
                :refNumber="this.totalTransactionMap" style="width:100%; height:100%" />
              <br />
              <br />
            </div>
          </div>
        </div>
        <div v-if="
          this.selectedProduct.brand !== 'vacanta' ||
          (this.cardStatisticsData.total_card >= 15 &&
            this.selectedProduct.brand === 'vacanta')
        " class="top-sellers">
          <div class="container-alb">
            <div class="usage-volume-container">
              <table cellpadding="0" cellspacing="0" width="100%">
                <tr>
                  <td align="left">
                    <p>
                      <strong>{{ $t("topSellers") }}</strong>
                    </p>
                  </td>
                  <td align="right"></td>
                </tr>
              </table>

              <br />
              <table cellpadding="7" cellspacing="0" width="100%">
                <tr v-for="(entry, index) in this.topAffiliates" :key="index" :class="index % 2 === 1 && 'gri-cell'">
                  <td width="40%">{{ entry.county | titleCase }}</td>
                  <td width="40%">
                    {{ entry.transactions_number }} transactions
                  </td>
                  <td width="15%">
                    {{
                      (
                        (entry.transactions_number / totalTransactionMap) *
                        100
                      ).toFixed(2)
                    }}
                    %
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="container-alb">
        <div class="usage-volume-container">
          <p>
            <strong>{{ $t("noTransactionsTitle") }}</strong>
          </p>
          <br />
          <p>{{ $t("noTransactionsMessage") }}</p>
        </div>
      </div>
    </div>
    <div id="modal_popup_errortr" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_errortr', false)" class="close-button">
          <img src="@/assets/images/close-button.svg"/>
        </a>
        <p v-html="$t('errorPopupMessage')">
        </p>
      </div>
    </div>
    <div id="modal_popup_error" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_error', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p>{{ $t("paperServerDownError") }}</p>
        <a class="button-orange" @click="showOrHideFirstPopup('modal_popup_error')">{{ $t("nextStepButtonLabel") }}</a>
      </div>
    </div>
    <div id="modal_popup_error_loading" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_error_loading', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="$t('errorMultibenMaintenanceBeneficiary', { name: this.customer.name })"></p>
        <a class="button-orange" @click="showModalPopup('modal_popup_error_loading', false)">{{ $t("nextStepButtonLabel")
        }}</a>
      </div>
    </div>
    <div id="modal_popup_maxVNDashboard" class="modal-popup">
      <div class="modal-popup-content maxVnPopupWidth">
        <a @click="showModalPopup('modal_popup_maxVNDashboard', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
          <b><p v-html="$t('newVNMessage')"></p></b>
          <a v-if="customerHasCardProductDejun" :href="'/customer/new_order?product=11'" class="button-orange" @click="showOrHideVNPopup()">{{ $t("orderNowName") }}</a>
          <a v-else class="button-orange" href="'/customer/new_order?product=11'" target-="_blank" @click="showOrHideVNPopup()">{{ $t("orderNowName") }}</a>

      </div>
    </div>
  </div>
</template>

<script>
import MapTemplate from "@/assets/components/MapTemplate.vue";
import TransactionChartCompany from "@/components/company/TransactionChartCompany.vue";
import InvoiceTableDesktop from "@/components/company/InvoiceTableDesktop.vue";
import customerBanner from "@/assets/json/customer_banner_2.js";

import httpServiceCustomer from "@/http/http-customer.js";
import httpServiceUser from "@/http/http-user.js";
import moment from "moment";
import Cookies from "js-cookie";
import $ from "jquery";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import NoElectronicInvoiceTemplate from "@/assets/components/NoElectronicInvoiceTemplate.vue";

export default {
  props: {
    customer: Object,
    user: Object,
    msg: String
  },
  components: {
    MapTemplate,
    TransactionChartCompany,
    InvoiceTableDesktop,
    "b-carousel": BCarousel,
    "b-carousel-slide": BCarouselSlide,
    NoElectronicInvoiceTemplate
  },
  data() {
    return {
      customerBanners: customerBanner,
      customerDataLoaded: false,
      customerBeneficiaries: null,
      token: null,
      dashboardInfos: null,
      transactionChartData: null,
      transactionMapData: null,
      cardStatisticsData: {
        // customer_id: 1,
        // product: 11,
        // total_card: 259,
        // blocked: 24,
        // activated: 187,
        // created: 19,
        // with_sms: 199
      },
      totalGiftOrders: 0,
      customerError: null,
      totalTransactionMap: 0,
      selectedProduct: null,
      selectedPeriod: 12,
      averageTransactionAmount: 0,
      averageTransactionNumber: 0,
      customerLastInvoices: null,
      cardRecommend: [
        {
          id: 1,
          text: this.$t("cardRecommendText1")
        },
        {
          id: 2,
          text: this.$t("cardRecommendText2")
        },
        {
          id: 3,
          text: this.$t("cardRecommendText3")
        },
        {
          id: 4,
          text: this.$t("cardRecommendText4")
        },
        {
          id: 5,
          text: this.$t("cardRecommendText5")
        }
      ],
      currentCardOffer: null,
      cardOfferIndex: 0,
      productIndex: 0,
      config: JSON.parse(sessionStorage.getItem("client_jsonFile")),
      order: null,
      lastOrders: null,
      orderError: null,
      statusOrder: [
        {
          status: this.$t("dashboardTrackingOrderPlaced"),
          date: "13.04.2020"
        },
        {
          status: this.$t("dashboardTrackingOrderProcessed"),
          date: "13.04.2020"
        },
        {
          status: this.$t("dashboardTrackingOrderPaid"),
          date: "14.04.2020"
        },
        {
          status: this.$t("dashboardTrackingOrderSent"),
          date: "14.04.2020"
        },
        {
          status: this.$t("dashboardTrackingOrderDelivery"),
          date: "15.04.2020"
        }
      ],
      statusTest: null
    };
  },
  methods: {
    selectProduct(index) {
      this.selectedProduct = this.customer.products[index];
      this.getCustomerDashboardInfo();
    },
    previousProduct() {
      let numberOfProduct = this.customer.products.length;
      this.productIndex--;
      if (this.productIndex < 0) {
        this.productIndex = numberOfProduct - 1;
      }
      this.selectProduct(this.productIndex);
    },
    nextProduct() {
      let numberOfProduct = this.customer.products.length;
      this.productIndex++;
      if (this.productIndex === numberOfProduct) {
        this.productIndex = 0;
      }
      this.selectProduct(this.productIndex);
    },
    selectPeriod(event) {
      this.selectedPeriod = event.target.value;
      this.getCustomerDashboardInfo();
    },
    selectCardOffer(index) {
      this.currentCardOffer = this.cardRecommend[index];
      if (this.$gtm) {
        this.$gtm.trackEvent({
          event: "click", // Event type [default = 'interaction'] (Optional)
          category: "Dashboard",
          action: "click",
          label: "selectCardStatGTM",
          value: index,
          noninteraction: false // Optional
        });
      }
    },
    previousCardOffer() {
      let numberOfCardOffers = this.cardRecommend.length;
      this.cardOfferIndex--;
      if (this.cardOfferIndex < 0) {
        this.cardOfferIndex = numberOfCardOffers - 1;
      }
      this.currentCardOffer = this.cardRecommend[this.cardOfferIndex];
    },
    nextCardOffer() {
      let numberOfCardOffers = this.cardRecommend.length;
      this.cardOfferIndex++;
      if (this.cardOfferIndex === numberOfCardOffers) {
        this.cardOfferIndex = 0;
      }
      this.currentCardOffer = this.cardRecommend[this.cardOfferIndex];
    },
    getCustomerDashboardInfo() {
      this.getDashboardGeneralInfos();
      this.getTransactionsVolumeMapData();
      this.getTransactionsVolumeChartData();
      this.getCardStatisticsData();
      this.getLastInvoices();
    },
    getDashboardGeneralInfos() {
      var formData =
        "?customer_id=" +
        this.customer.company_id +
        "&brand=" +
        this.selectedProduct.brand;

      httpServiceCustomer.customerService
        .getDashboardInfos(
          this.customer.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching dashboard info data"
          };
        })
        .then(response => {
          if (response.status == 200) {
            this.dashboardInfos = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching dashboard info data"
            };
          } else {
            this.dashboardError = { description: response.description };
          }
        });
    },
    getTransactionsVolumeMapData() {
      if (!this.selectedProduct.card) {
        return;
      }
      var transactionsData =
        "?type=" +
        "tr.summary" +
        "&customer_id=" +
        this.customer.company_id +
        "&product=" +
        this.selectedProduct.card +
        "&months=" +
        this.selectedPeriod;

      httpServiceCustomer.customerService
        .getTransactionVolume(transactionsData, Cookies.get("token"))
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching transactions"
          };
        })
        .then(response => {
          if (response.status == 200) {
            this.totalTransactionMap = response.headers["x-up-count1"] * 1;
            this.transactionMapData = response.data;
            if (this.transactionMapData.length === 0) {
              this.totalTransactionMap = 0;
            }
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching transactions"
            };
            this.totalTransactionMap = 0;
          } else {
            this.dashboardError = { description: response.description };
            this.totalTransactionMap = 0;
          }
        });
    },
    getTransactionsVolumeChartData() {
      if (!this.selectedProduct.card) {
        return;
      }
      var transactionsData =
        "?type=" +
        "tr.number" +
        "&customer_id=" +
        this.customer.company_id +
        "&product=" +
        this.selectedProduct.card +
        "&months=" +
        this.selectedPeriod;

      httpServiceCustomer.customerService
        .getTransactionVolume(transactionsData, Cookies.get("token"))
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching transactions"
          };
          this.totalTransactionMap = 0;
        })
        .then(response => {
          if (response.status == 200) {
            this.totalTransactionMap = response.headers["x-up-count1"] * 1;
            this.transactionChartData = response.data;
            if (
              this.transactionMapData &&
              (!this.transactionMapData.length ||
                this.transactionMapData.length === 0)
            ) {
              this.totalTransactionMap = 0;
            }
            this.fillChartData();
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching transactions"
            };
            this.totalTransactionMap = 0;
          } else {
            this.dashboardError = { description: response.description };
            this.totalTransactionMap = 0;
          }
        });
    },
    getCardStatisticsData() {
      if (!this.selectedProduct.card) {
        this.cardStatisticsData.total_card = 0;
        return;
      }
      var transactionsData =
        "?customer_id=" +
        this.customer.company_id +
        "&product=" +
        this.selectedProduct.card;

      httpServiceCustomer.customerService
        .getCardsStatistics(transactionsData, Cookies.get("token"))
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching transactions"
          };
        })
        .then(response => {
          if (response.status == 200) {
            this.cardStatisticsData = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching transactions"
            };
          } else {
            this.dashboardError = { description: response.description };
          }
        });
    },
    fillChartData() {
      if (this.transactionChartData != null) {
        let chartDataTemp = [];
        let index = this.transactionChartData.length - 1;
        let totalAverage = 0;
        let totalTransactions = 0;
        while (index >= 0) {
          totalAverage += this.transactionChartData[index].transactions_amount;
          totalTransactions += this.transactionChartData[index]
            .transactions_number;
          chartDataTemp.push({
            date: moment(
              this.transactionChartData[index].transactions_date,
              "YYYY-MM-DD"
            ).format("DD MMM YYYY"),
            average: this.transactionChartData[index].transactions_amount,
            count: this.transactionChartData[index].transactions_number
          });
          index--;
        }
        this.averageTransactionAmount = totalAverage / totalTransactions;
        this.averageTransactionNumber = Math.ceil(
          totalTransactions / (this.selectedPeriod * 30)
        );

        let dateTransactions = chartDataTemp.map(a => a.date);
        this.transactionChartData = {
          labels: dateTransactions,
          datasets: [
            {
              label: this.$t("averageChartLabel"),
              yAxisID: "amount",
              backgroundColor: "#f79000",
              borderColor: "#f79000",
              borderWidth: 2,
              data: chartDataTemp.map(a => a.average),
              fill: false,
              pointRadius: 0
            },
            {
              label: this.$t("countChartLabel"),
              yAxisID: "count",
              borderWidth: 2,
              backgroundColor: "#864e00",
              borderColor: "#864e00",
              data: chartDataTemp.map(a => a.count),
              fill: false,
              pointRadius: 0
            }
          ]
        };
      }
    },
    getLastInvoices() {
      var invoicesData =
        "?type=" +
        "customer" +
        "&brand=" +
        this.selectedProduct.brand +
        "&paid=L,R,P,U" +
        "&start_date=" +
        "20200101" +
        "&end_date=" +
        moment().format("YYYYMMDD") +
        "&per_page=2" +
        "&page=1" +
        "&desc=date" +
        "&sort=desc";

      httpServiceUser.userService
        .getCompanyInvoices(
          this.customer.company_id,
          invoicesData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching last invoices"
          };
        })
        .then(response => {
          if (response.status == 200) {
            this.customerLastInvoices = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching last invoices"
            };
          } else {
            this.dashboardError = { description: response.description };
          }
        });
    },
    getLastOrder() {
      var ordersData =
        "?type=" +
        "customer" +
        "&order_type=" +
        "PAPER" +
        "&brand=" +
        this.selectedProduct.brand +
        "&start_date=" +
        "20200101" +
        "&end_date=" +
        moment().format("YYYYMMDD") +
        "&status=L,P,N,E" + // status N,P,E(expediat)
        "&per_page=1" +
        "&page=1" +
        "&desc=order_date" +
        "&sort=desc";

      httpServiceCustomer.customerService
        .searchOrders(
          ordersData,
          this.customer.company_id,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.lastOrders = response.data;
            this.getAWBSForOrder(this.lastOrders[0]);
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: response.data,
              description: "Error fetching orders"
            };
          } else {
            this.orderError = { description: response.description };
          }
          this.showModalPopup("modal_popup_loading", false);
        })
        .catch(error => {
          this.orderError = {
            code: error.response.status,
            description: error.response.statusText
          };
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    getAWBSForOrder(order) {
      httpServiceCustomer.customerService
        .getAWBForOrder(
          this.customer.company_id,
          order.id,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          if (error.response) {
            this.showModalPopup("modal_popup_loading", false);
          }
          this.showModalPopup("modal_popup_loading", false);
        })
        .then(response => {
          if (response.status == 200) {
            this.lastOrders[0].awb = response.data;
            this.getAWBStatus(this.lastOrders[0].awb.addresses[0].awb);
          } else if (response.status == 400 || response.status == 403) {
            this.lastOrders[0].awb = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    getAWBStatus(awb) {
      httpServiceCustomer.customerService
        .getAWBStatus(
          this.customer.company_id,
          this.lastOrders[0].id,
          awb,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          if (error.response) {
            this.showModalPopup("modal_popup_loading", false);
          }
          this.showModalPopup("modal_popup_loading", false);
        })
        .then(response => {
          if (response.status == 200) {
            this.statusTest = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.statusTest = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    getStyle(width) {
      if (this.cardStatisticsData.total_card) {
        return "width:" + width + "%";
      } else {
        return "width:0%";
      }
    },
    filterDashboardInfos(name) {
      for (let index in this.dashboardInfos) {
        if (this.dashboardInfos[index].name === name) {
          return this.dashboardInfos[index].value;
        }
      }
      return null;
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    showOrHideFirstPopup(modal_id) {
      Cookies.set("first", true);
      this.showModalPopup(modal_id, false);
    },
    showOrHideVNPopup() {
      let x = parseInt(
        Cookies.get("maxVNPopoupShowed2")
          ? Cookies.get("maxVNPopoupShowed2")
          : 0
      );
      if (x <= 2) {
        this.showModalPopup("modal_popup_maxVNDashboard", true);
        x++;
        Cookies.set("maxVNPopoupShowed2", x);
      } else {
        this.showModalPopup("modal_popup_maxVNDashboard", false);
      }
    },
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("user_role");
      Cookies.remove("token");
      let hasFxlAccess = this.flxUserLevel().hasAccess;
      Cookies.remove("flexbenClient");
      Cookies.remove("flxCookie");
      if (hasFxlAccess) {
        this.$router.push({
          name: "loginMultibenefits",
          params: { loginUserSelected: false }
        });
      } else {
        this.$router.push({
          name: "login",
          params: { loginUserSelected: false }
        });
      }
    },
    sendClickToGA(category, action, label) {
      if (this.$ga) {
        this.$ga.event({
          eventCategory: category,
          eventAction: action,
          eventLabel: label
        });
      }
    },
    customerHasCardProductDejun() {
      let hasProduct = false;
      this.customer.products.forEach(prod => {
        if (prod.card === 11) {
          hasProduct = true;
        }
      });
      return hasProduct;
    },
  },
  computed: {
    topAffiliates() {
      if (!this.transactionMapData) {
        return;
      }
      if (this.transactionMapData.length <= 10) {
        return this.transactionMapData;
      } else {
        let toAffiliates = [];
        let i = 0;
        while (i < 10) {
          toAffiliates.push(this.transactionMapData[i]);
          i += 1;
        }

        return toAffiliates;
      }
    }
  },
  filters: {
    titleCase: function (value) {
      if (!value) return "";
      value = value.toLowerCase().split(" ");
      for (var i = 0; i < value.length; i++) {
        value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
      }
      return value.join(" ");
    }
  },
  mounted() {
    this.token = Cookies.get("token");
    this.url = Cookies.get("userLink");
    $(".top-sellers .container-alb").height(
      $(".usage-country .container-alb").height()
    );
    if (!Cookies.get("first")) {
      var now = moment();
      if (now.hour() < 7) {
        this.showModalPopup("modal_popup_error", true);
      }
    }
    //this.showModalPopup("modal_popup_errortr", true);
    this.showOrHideVNPopup();
    // if (Cookies.get("flxCookie") === 'client'){
    //   this.showModalPopup("modal_popup_error_loading", true);
    // }

  },
  created() {
    this.selectProduct(0);
    this.selectCardOffer(0);
    setTimeout(() => {
      $(".top-sellers .container-alb").height(
        $(".usage-country .container-alb").height()
      );
    }, 300);
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.status-text {
  float: left;
  padding-left: 2.5%;
  padding-right: 2.5%;
  margin-top: 30px;
  width: 20%;
  text-align: center;
}

.order-statusbar {
  background: #f49400;
  height: 24px;
  border-radius: 50px;
  position: relative;
}

.order-trackbar {
  width: 100%;
  background: #d7d7d7;
  border-radius: 50px;
  height: 24px;
  margin-top: 20px;
  position: relative;
}

.numbers-left {
  width: 30%;
  float: left;
  text-align: center;
}

.numbers-left h1 {
  font-size: 60px;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  color: #f49400;
}

.numbers-left h1 sup {
  font-size: 18px;
  font-weight: normal;
  color: #585858;
  margin: 0px;
  vertical-align: super;
}

.recommandations {
  width: 38%;
  float: right;
  position: relative;
  min-height: 294px;
}

.statuses {
  width: 60%;
  float: left;
}

.grafica {
  background: #d8d8d8;
  height: 30px;
  position: relative;
  color: #fff;
  text-align: right;
  padding: 10px 10px 0 10px;
}

.grafica:hover {
  cursor: pointer;
}

.grafica:hover>.grafica-fill {
  background: #be770a;
}

.grafica-fill {
  background: #f59100;
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  z-index: 9;
}

.grafica>span {
  position: relative;
  z-index: 99;
}

.active-dark {
  background: #be770a !important;
}

.card-recomandations {
  left: 0;
  position: absolute;
  width: 100%;
  top: calc(50% + 20px);
  transform: translateY(-50%);
}

.right-arrow {
  width: 11px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.content-rec {
  width: auto;
  padding: 0 30px;
  text-align: center;
}

.left-arrow {
  width: 11px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.usage-country {
  width: 48%;
  float: left;
}

.top-sellers {
  width: 48%;
  float: right;
}

.usage-volume {
  width: 65%;
  float: right;
}

.usage-volume img {
  width: 100% !important;
}

.allstats-customer {
  width: 100%;
  background: #fff;
  border-top-right-radius: 5px;
  border-bottom: 2px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-bottom: 40px;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {}

@media (max-width: 1200px) {}

@media (max-width: 1200px) and (-ms-high-contrast: none),
(-ms-high-contrast: active) {}

@media (max-width: 1096px) {}

@media (max-width: 1096px) and (-ms-high-contrast: none),
(-ms-high-contrast: active) {}

@media (max-width: 1024px) {}

@media (max-width: 1024px) and (-ms-high-contrast: none),
(-ms-high-contrast: active) {}

@media (max-width: 1003px) {
  .statuses {
    width: 100%;
    float: none;
    border-bottom: 1px solid #ddd;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .content-rec {
    text-align: center;
  }

  .content-rec h2 {
    font-size: 16px;
  }

  .usage-country {
    width: 100%;
    float: none;
  }

  .recommandations {
    width: 100%;
    float: none;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .usage-volume {
    width: 100%;
    float: none;
  }

  .top-sellers {
    width: 100%;
    float: none;
  }

  .numbers-left {
    width: 100%;
    float: none;
    text-align: center;
  }

  .status-text {
    font-size: 12px;
  }
}
</style>
